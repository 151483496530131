import { createApp } from 'vue'
import App from './App.vue'
import router from './router'



import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import Pagination from 'v-pagination-3';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import '@/assets/style.css'

import axios from "axios";
// axios.defaults.baseURL = 'http://127.0.0.1:8000/'
axios.defaults.baseURL = 'https://api-pos.kraja.co.id/public/'


createApp(App)
.component('pagination', Pagination)
.use(VueSweetalert2)
.use(router)
.mount('#app')
