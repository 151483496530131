import auth from '@/authenticate/auth'
import axios from 'axios';
import {
  createRouter,
  createWebHistory
} from 'vue-router'

const routes = [{
    path: '/',
    name: 'login',
    component: () => import( /* webpackChunkName: "about" */ '../views/Auth/Login.vue')
  },
  {
    path: '/login',
    name: 'loginPage',
    component: () => import( /* webpackChunkName: "about" */ '../views/Auth/Login.vue')
  },
  {
    path: '/change-password',
    name: 'changePassword',
    component: () => import( /* webpackChunkName: "about" */ '../views/Auth/ChangePassword.vue')
  },
  {
    path: '/home',
    beforeEnter: auth,
    name: 'home',
    component: () => import( /* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/report',
    beforeEnter: auth,
    name: 'report',
    component: () => import( /* webpackChunkName: "about" */ '../views/Report.vue')
  },
  {
    path: '/scan-qr',
    beforeEnter: auth,
    name: 'scanQr',
    component: () => import('../views/ScanQR.vue')
  },
  {
    path: '/custom',
    beforeEnter: auth,
    name: 'custom',
    component: () => import('../views/Custom.vue')
  },
  {
    path: '/cart',
    beforeEnter: auth,
    name: 'cart',
    component: () => import("../views/Cart.vue")
  },
  {
    path: '/admin/produk',
    component: () => import("@/views/Admin/Produk/Index.vue"),
    beforeEnter: (to, from, next) => {
      axios.get("/api/user", {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((resp) => {
        if (resp.data.role == "Admin") {
          next();
        } else {
          next("/home");
        }
      })
    },
  },
  {
    path: '/admin/produk/upload-produk',
    component: () => import("@/views/Admin/Produk/UploadProduk.vue"),
    beforeEnter: (to, from, next) => {
      axios.get("/api/user", {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((resp) => {
        if (resp.data.role == "Admin") {
          next();
        } else {
          next("/home");
        }
      })
    },
  },
  {
    path: '/admin/produk/tambah-produk',
    component: () => import("@/views/Admin/Produk/TambahProduk.vue"),
    beforeEnter: (to, from, next) => {
      axios.get("/api/user", {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((resp) => {
        if (resp.data.role == "Admin") {
          next();
        } else {
          next("/home");
        }
      })
    },
  },
  {
    path: '/admin/produk/edit-produk/:id',
    component: () => import("@/views/Admin/Produk/EditProduk.vue"),
    beforeEnter: (to, from, next) => {
      axios.get("/api/user", {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((resp) => {
        if (resp.data.role == "Admin") {
          next();
        } else {
          next("/home");
        }
      })
    },
  },

  // Tailoring
  {
    path: '/admin/tailoring',
    component: () => import("@/views/Admin/Tailoring/Index.vue"),
    beforeEnter: (to, from, next) => {
      axios.get("/api/user", {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((resp) => {
        if (resp.data.role == "Admin") {
          next();
        } else {
          next("/home");
        }
      })
    },
  },
  {
    path: '/admin/tailoring/upload-tailoring',
    component: () => import("@/views/Admin/Tailoring/UploadTailoring.vue"),
    beforeEnter: (to, from, next) => {
      axios.get("/api/user", {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((resp) => {
        if (resp.data.role == "Admin") {
          next();
        } else {
          next("/home");
        }
      })
    },
  },
  {
    path: '/admin/tailoring/tambah-tailoring',
    component: () => import("@/views/Admin/Tailoring/TambahTailoring.vue"),
    beforeEnter: (to, from, next) => {
      axios.get("/api/user", {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((resp) => {
        if (resp.data.role == "Admin") {
          next();
        } else {
          next("/home");
        }
      })
    },
  },
  {
    path: '/admin/tailoring/edit-tailoring/:id',
    component: () => import("@/views/Admin/Tailoring/EditTailoring.vue"),
    beforeEnter: (to, from, next) => {
      axios.get("/api/user", {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((resp) => {
        if (resp.data.role == "Admin") {
          next();
        } else {
          next("/home");
        }
      })
    },
  },
  {
    path: '/users',
    component: () => import("@/views/Admin/User/Index.vue"),
    beforeEnter: (to, from, next) => {
      axios.get("/api/user", {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((resp) => {
        if (resp.data.role == "Admin" || resp.data.role == "Supervisor") {
          next();
        } else {
          next("/home");
        }
      })
    }
  },
  {
    path: '/users/add',
    component: () => import("@/views/Admin/User/Create.vue"),
    beforeEnter: (to, from, next) => {
      axios.get("/api/user", {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((resp) => {
        if (resp.data.role == "Admin" || resp.data.role == "Supervisor") {
          next();
        } else {
          next("/home");
        }
      })
    }
  },
  {
    path: '/users/edit/:id',
    component: () => import("@/views/Admin/User/Edit.vue"),
    beforeEnter: (to, from, next) => {
      axios.get("/api/user", {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((resp) => {
        if (resp.data.role == "Admin" || resp.data.role == "Supervisor") {
          next();
        } else {
          next("/home");
        }
      })
    }
  },

  {
    path: '/customers',
    component: () => import("@/views/Admin/Customer/Index.vue"),
    beforeEnter: (to, from, next) => {
      axios.get("/api/user", {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((resp) => {
        if (resp.data.role == "Admin" || resp.data.role == "Supervisor") {
          next();
        } else {
          next("/home");
        }
      })
    }
  },
  {
    path: '/customers/add',
    component: () => import("@/views/Admin/Customer/Create.vue"),
    beforeEnter: (to, from, next) => {
      axios.get("/api/user", {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((resp) => {
        if (resp.data.role == "Admin" || resp.data.role == "Supervisor") {
          next();
        } else {
          next("/home");
        }
      })
    }
  },
  {
    path: '/customers/edit/:id',
    component: () => import("@/views/Admin/Customer/Edit.vue"),
    beforeEnter: (to, from, next) => {
      axios.get("/api/user", {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((resp) => {
        if (resp.data.role == "Admin" || resp.data.role == "Supervisor") {
          next();
        } else {
          next("/home");
        }
      })
    }
  },
  {
    path: '/customers/upload-customers',
    component: () => import("@/views/Admin/Customer/UploadCustomer.vue"),
    beforeEnter: (to, from, next) => {
      axios.get("/api/user", {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((resp) => {
        if (resp.data.role == "Admin") {
          next();
        } else {
          next("/home");
        }
      })
    },
  },
  {
    path: '/invoice-settings',
    component: () => import("@/views/Admin/InvoiceSetting/Index.vue"),
    beforeEnter: (to, from, next) => {
      axios.get("/api/user", {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((resp) => {
        if (resp.data.role == "Admin") {
          next();
        } else {
          next("/home");
        }
      })
    },
  },
  {
    path: '/invoice-settings/edit/:id',
    component: () => import("@/views/Admin/InvoiceSetting/Edit.vue"),
    beforeEnter: (to, from, next) => {
      axios.get("/api/user", {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((resp) => {
        if (resp.data.role == "Admin") {
          next();
        } else {
          next("/home");
        }
      })
    },
  },

  {
    path: '/outlet',
    component: () => import("@/views/Admin/Outlet/Index.vue"),
    beforeEnter: (to, from, next) => {
      axios.get("/api/user", {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((resp) => {
        if (resp.data.role == "Admin") {
          next();
        } else {
          next("/home");
        }
      })
    },
  },

  {
    path: '/outlet/add',
    component: () => import("@/views/Admin/Outlet/Create.vue"),
    beforeEnter: (to, from, next) => {
      axios.get("/api/user", {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((resp) => {
        if (resp.data.role == "Admin") {
          next();
        } else {
          next("/home");
        }
      })
    },
  },

  {
    path: '/outlet/edit/:id',
    component: () => import("@/views/Admin/Outlet/Edit.vue"),
    beforeEnter: (to, from, next) => {
      axios.get("/api/user", {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((resp) => {
        if (resp.data.role == "Admin") {
          next();
        } else {
          next("/home");
        }
      })
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router